// -- copyright
// OpenProject is a project management system.
// Copyright (C) 2012-2015 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.rdoc for more details.
// ++
import {Component, ViewChild, Input} from "@angular/core";
import {I18nService} from "core-app/core/i18n/i18n.service";
import {SubscriptionService} from "./subscription.service";
import {invoicableCountries} from "../invoice-form/invoice.const";
import {BillingService} from "../billing-form/billing.service";
import { BillingFormComponent } from "../billing-form/billing-form.component";
import { ToastService } from "core-app/shared/components/toaster/toast.service";

@Component({
  selector: 'subscription-form',
  templateUrl: './subscription-form.html',
  styleUrls: ['./subscription-form.sass']
})
export class SubscriptionFormComponent {
  public isInvoice:boolean = false;
  public isQuote:boolean = false;

  @Input() public chargebeeSite:string;

  @ViewChild(BillingFormComponent) private _billingForm:BillingFormComponent;

  public text = {
    chosePlan: this.I18n.t('js.subscription.label_chose_plan'),
    subscribe: this.I18n.t('js.subscription.label_subscribe'),
    paymentOptions: this.I18n.t('js.subscription.plan.payment_options'),
    payPerInvoice: this.I18n.t('js.subscription.payment.pay_per_invoice'),
    paymentInfoAnnually: this.I18n.t('js.subscription.payment.payment_info_annually'),
    visa: this.I18n.t('js.subscription.payment.visa'),
    bankTransfer: this.I18n.t('js.subscription.payment.bank_transfer'),
    masterCard: this.I18n.t('js.subscription.payment.master_card'),
    maestro: this.I18n.t('js.subscription.payment.maestro'),
    sepa: this.I18n.t('js.subscription.payment.sepa'),
    americanExpress: this.I18n.t('js.subscription.payment.american_express'),
    requestQuote: this.I18n.t('js.subscription.label_request_quote'),
    cancel: this.I18n.t("js.subscription.label_cancel"),
    invoiceTitle: {
      invoice: this.I18n.t('js.subscription.billing_info.billing_info'),
      quote: this.I18n.t('js.subscription.quote_info.quote_info'),
    },
    or: this.I18n.t('js.or'),
  };

  public constructor(
    protected I18n:I18nService,
    readonly subscription:SubscriptionService,
    readonly billing:BillingService,
    readonly toastService:ToastService,
  ) {
    this.preSelect();
  }

  ngOnInit() {
    (window as any).Chargebee.init({site: this.chargebeeSite});
  }

  public preSelect() {
    const queryParams = new URLSearchParams(window.location.search)
    let quote = queryParams.get('quote');
    let edition = queryParams.get('edition');

    if (quote) {
      this.isQuote = true;
    }

    if (edition) {
      if (edition == "bim" && !window.OpenProject.isBimEdition) {
        this.toastService.addWarning(this.I18n.t('js.warning_different_edition_classic'));
      } else if (edition == "classic" && window.OpenProject.isBimEdition) {
        this.toastService.addWarning(this.I18n.t('js.warning_different_edition_bim'));
      }
    }
  }

  public updatePaymentOption(optionKey:number) {
    this.billing.updateSubscriptionPeriodByKey(optionKey)
  }

  public toggleInvoiceFormVisibility(e:any){
    this.isInvoice = e.target.checked;

    this._billingForm.updateBillingInformation();
  }

  public toggleQuoteFormVisibility(e:Event, val:boolean) {
    e.preventDefault();
    this.isQuote = val;

    // quotes are only available for n-yearly subscriptions
    if (this.billing.currentSubscriptionPeriod.key < 12) {
      this.updatePaymentOption(12);
    }

    this._billingForm.updateBillingInformation();
  }

  public get displayInvoiceForm() {
    return (this.isInvoice && this.isInvoiceFormAllowed);
  }

  public get displayQuoteForm() {
    return this.isQuote;
  }

  public submitButtonText() {
    return this.displayQuoteForm ? this.text.requestQuote : this.text.subscribe;
  }

  public quoteButtonText() {
    return this.displayQuoteForm ? this.text.cancel : this.text.requestQuote;
  }

  public invoiceFormTitle() {
    return this.displayQuoteForm ? this.text.invoiceTitle.quote : this.text.invoiceTitle.invoice;
  }

  public get isInvoiceFormAllowed():boolean {
    return this.isYearly() && this.isInvoiceAllowedForCurrentCountry();
  }

  public isMonthly():boolean {
    return this.billing.currentSubscriptionPeriod.key === 1;
  }

  private isYearly(): boolean {
    return this.billing.currentSubscriptionPeriod.key >= 12;
  }

  private isInvoiceAllowedForCurrentCountry():boolean {
    return _.includes(invoicableCountries, this.billing.currentCountryOption.key);
  }

  public subscribe(e:Event) {
    e.preventDefault();

    if (this.displayInvoiceForm) {
      this.subscription.subscribeOffline(
        this.billing.currentSupportPlan,
        this.billing.userCount,
        this.billing.currentCountryOption.key,
        this.billing.currentSubscriptionPeriod.key,
      );
    } else if(this.displayQuoteForm) {
      this.subscription.createQuote(
        this.billing.currentSupportPlan,
        this.billing.userCount,
        this.billing.currentCountryOption.key,
        this.billing.currentSubscriptionPeriod.key,
      );
    } else {
      this.subscription.subscribeOnline(this.billing.currentSupportPlan, this.billing.userCount, this.billing.currentCountryOption, this.billing.currentSubscriptionPeriod);
    }
  }
}